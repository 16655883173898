<template>
  <b-modal
    id="new-tiss-guide-modal"
    hide-header
    hide-footer
    centered
    size="lg"
    @show="onShow"
    @hidden="clearModal"
  >
    <div class="modal-header">
      <div>
        <p class="modal-title">Nova guia</p>
      </div>
      <div>
        <Close class="icon" @click="clearModal" />
      </div>
    </div>
    <div class="around-content">
      <b-row>
        <b-col cols="6">
          <PatientInput
            required
            v-model="form.patient"
            :error="false"
            @select="onSelectPatient"
            :disabled="true"
          />
        </b-col>
        <b-col cols="6">
          <b-form-group>
            <label for="health_plan">Convênio</label>
            <multiselect
              :loading="loading"
              id="health_plan"
              :showLabels="false"
              v-model="form.healthPlan"
              track-by="id"
              label="label"
              :options="healthPlans"
              @select="selectHealthPlan"
              placeholder="Selecionar"
              class="with-border"
              :disabled="disabledHealthPlan"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
              <template slot="noOptions">
                <span v-if="!form.patient">Selecione um paciente</span>
                <span v-else>Nenhuma opção de convênio com TISS ativo</span>
              </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <b-form-group>
            <label for="guide_type">Tipo de guia</label>
            <multiselect
              id="guide_type"
              v-model="form.guide_type"
              :options="guideOptions"
              label="label"
              placeholder="Selecionar"
              class="with-border"
              :show-labels="false"
            >
              <template slot="caret">
                <div class="chevron">
                  <ChevronDown />
                </div>
              </template>
<!--              <template slot="option" slot-scope="props">
                <div>{{ props.option.label }}</div>
                <p class="disabled-description" v-if="props.option.$isDisabled">
                  Selecione apenas um procedimento para habilitar esta opção
                </p>
              </template>-->
              <template slot="noOptions">
                <span v-if="form.healthPlan"> Nenhuma opção </span>
                <span v-else> Selecione um convênio </span>
              </template>
              <template slot="noResult"> Nenhum resultado </template>
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>

      <div class="wrapper-button">
        <b-button variant="primary" :disabled="!canSave" @click="createGuide">
          Criar nova guia
          <b-spinner v-if="loadingCreating" small variant="light" />
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import itemApi from '@items/api'
import { getCurrentClinic } from '@/utils/localStorageManager'
import { handleGuideSelected } from '@/utils/invoicingTissHelper'
import { getDecreaseOrIncreaseFactor } from '@/components/Tiss/utils/valueRules'

export default {
  name: 'NewTissGuideModal',
  props: {
    patient: Object,
    healthPlan: Object,
    items: Array,
    disabledHealthPlan: {
      type: Boolean,
      default: true
    }
  },
  components: {
    Close: () => import('@/assets/icons/close.svg'),
    ChevronDown: () => import('@/assets/icons/chevron-down.svg'),
    PatientInput: () => import('@/components/General/PatientInput')
  },
  data() {
    return {
      clinic: getCurrentClinic(),
      clinicHealthPlan: null,
      healthPlans: [],
      guideOptions: [],
      loading: false,
      loadingCreating: false,
      form: {
        patient: null,
        healthPlan: null,
        guide_type: null
      }
    }
  },
  computed: {
    canSave() {
      return this.form.patient && this.form.healthPlan && this.form.guide_type
    }
  },
  methods: {
    async onShow() {
      if (this.patient?.id) {
        this.form.patient = this.patient
        await this.getHealthPlans()
      }
      if (this.healthPlan?.id) {
        this.form.healthPlan = this.healthPlan
        await this.getHealthPlanByPatient(this.healthPlan.id)
        await this.getClinicHealthPlan(this.healthPlan.id)
      }
    },
    closeModal() {
      this.$bvModal.hide('new-tiss-guide-modal')
    },
    clearModal() {
      this.form = {
        patient: null,
        healthPlan: null,
        guide_type: null
      }
      this.loading = false
      this.guideOptions = []
      this.healthPlans = []
      this.closeModal()
    },
    async getHealthPlans() {
      this.loading = true
      try {
        const { data } = await this.api.getPatientPlansTissActive(this.form.patient.id, this.clinic.id)
        this.healthPlans = data.map(plan => ({
          value: plan.health_plan.id,
          label: plan.health_plan.fantasy_name
        }))
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        this.loading = false
      }
    },
    async getClinicHealthPlan(healthPlanId) {
      await this.getHealthPlanByPatient(healthPlanId)
      this.$emit('setClinicHealthPlanToPending', this.clinicHealthPlan)
      this.setGuideTypes()
    },
    setGuideTypes() {
      this.guideOptions = []
      const guideOptions = [
        { label: 'Guia de consulta', value: 'consultation_guide',  $isDisabled: this.items.length > 1 },
        { label: 'Guia SP/SADT', value: 'sp_sadt_guide' },
        // { label: 'Guia de solicitação de internação', value: 'hospitalization_request_guide', $isDisabled: this.items.length > 0 },
        // { label: 'Guia de resumo de internação', value: 'hospitalization_summary_guide', $isDisabled: this.items.length > 0},
        { label: 'Guia de honorários', value: 'fee_guide' },
        // { label: 'Guia de outras despesas', value: 'other_expenses_guide', $isDisabled: this.items.length > 0 }
      ]
      const types = this.clinicHealthPlan?.invoicing_tiss_setting.tiss_using_guide_type
      guideOptions.forEach(type => {
        if (types[type.value]) {
          this.guideOptions.push(type)
        }
      })
    },
    async onSelectPatient(patient) {
      this.healthPlans = []
      this.form.healthPlan = null

      this.guideOptions = []
      this.form.guide_type = null
      if (!patient) {
        return
      }
      await this.getHealthPlans()
    },
    async selectHealthPlan(healthPlan) {
      this.guideOptions = []
      this.form.healthPlan = healthPlan
      await this.getClinicHealthPlan(healthPlan.value)
    },
    async updateHealthPlan() {
      await this.getClinicHealthPlan(this.form.healthPlan.value)
    },
    handleGuide() {
      const modal = handleGuideSelected(this.form.guide_type?.value)
      this.$bvModal.show(modal)
    },
    updateValueByRules(procedureForm, invoicingTissSetting, form) {
      if (!form.degree_of_participation) return
      const [decreaseOrIncreaseFactor, unitaryValue, value] = getDecreaseOrIncreaseFactor(procedureForm, invoicingTissSetting, form.degree_of_participation)
      form.unitary_value = unitaryValue
      form.decrease_or_increase_factor = decreaseOrIncreaseFactor
      form.value = value
      // this.rule = rule
    },
    async createGuide() {
      this.loadingCreating = true
      let tissGuide = {}
      try {
        if (this.form.guide_type.value === 'consultation_guide') {
          const item = await this.getItemWithHealthPlanProps(this.items[0])
          tissGuide = {
            appointment_id: this?.items[0]?.appointment_id,
            health_plan: this.healthPlan,
            invoicing_tiss_setting_id: this.clinicHealthPlan?.invoicing_tiss_setting.id,
            item_id: item.id,
            item: item,
            professional_id_fill: this.items[0]?.appointment?.professional_id,
            medical_fee: item?.health_plan_props?.medical_fee,
            anesthetic_port: item?.health_plan_props?.anesthetic_port,
            appointment_item_id: this.items[0]?.id
          }
        } else if (this.form.guide_type.value === 'sp_sadt_guide' || this.form.guide_type.value === 'fee_guide') {
          tissGuide = {
            appointment_id: null,
            health_plan: this.healthPlan,
            invoicing_tiss_setting_id: this.clinicHealthPlan?.invoicing_tiss_setting.id,
            professional_id_fill: this.items[0]?.appointment?.professional_id,
          }

          const guideItems = await Promise.all(this.items.map(async appointmentItem => {
            let professional = null
            const item = await this.getItemWithHealthPlanProps(appointmentItem)
            const value = this.procedureValue(item.health_plan_props)

            if (appointmentItem.appointment?.professional_id && ['1', '2', '4'].includes(item.code.charAt(0))) {
              const onlendingProfessional = await this.api.getBeneficiaryByPersonId(appointmentItem.appointment.professional_id).then(({data}) => {
                if(Object.keys(data).length > 0){
                  return {id: data.id, name: data.name};
                } else {
                  return null;
                }
              });
              try {
                const { data } = await this.api.getItemProfessional(this.clinicHealthPlan?.invoicing_tiss_setting.id, appointmentItem.appointment.professional_id, appointmentItem.item_id)
                professional = data
              }
              catch (error) {
                if(appointmentItem.appointment.professional_id){
                  professional = {
                    'reference_sequence': null,
                    'professional_id': null,
                    'degree_of_participation': '12',
                    'code_type': null,
                    'contractor_code': null,
                    'professional_council': null,
                    'professional_council_registration': null,
                    'uf': null,
                    'cbo_code': null,
                    'decrease_or_increase_factor': null,
                    'custom': false,
                    'unitary_value': null,
                    'value': null
                  }             
                }
              }
              
              professional.onlending_professional = onlendingProfessional;
            }

            const tissGuideItemForm = {
              item: {
                ...item,
                invoicing_tiss_setting_id: this.clinicHealthPlan?.invoicing_tiss_setting.id,
              },
              professionals: professional ? [professional] : [],
              execution_date: appointmentItem?.appointment?.start_datetime ? this.moment(appointmentItem?.appointment?.start_datetime).format('YYYY-MM-DD') : null,
              start_time: appointmentItem?.appointment?.start_datetime ? this.moment(appointmentItem?.appointment?.start_datetime).format('HH:mm') : null,
              end_time: appointmentItem?.appointment?.end_datetime ? this.moment(appointmentItem?.appointment?.end_datetime).format('HH:mm') : null,
              table_name: appointmentItem?.item?.health_plan_props?.table_number || 22,
              procedure_id: appointmentItem?.item_id,
              description: appointmentItem?.item?.name,
              quantity: 1,
              individual: null,
              pathway: null,
              technique: null,
              decrease_or_increase_factor: 1,
              custom: false,
              unitary_value: value,
              value: value,
              medical_fee: item?.health_plan_props?.medical_fee,
              anesthetic_port: item?.health_plan_props?.anesthetic_port,
              total_value: value,
              appointment_item_id: appointmentItem.id,
            }

            professional && this.updateValueByRules(tissGuideItemForm, this.clinicHealthPlan?.invoicing_tiss_setting, professional)
            return tissGuideItemForm
          }))
            this.$set(tissGuide, 'items', guideItems)
        }

        this.$emit('createGuide', { tissGuide, clinicHealthPlan: this.clinicHealthPlan })
        this.handleGuide()
        this.closeModal()
      } catch (error) {
        this.$toast.warning(error.message)
      } finally {
        this.loadingCreating = false
      }
    },
    async getItemWithHealthPlanProps(appointmentItem) {
      if(!appointmentItem) return
      try {
        const { data } = await itemApi.getItem(appointmentItem.item_id, this.clinicHealthPlan.id, appointmentItem?.plan_id)
        return data;
      } catch(err) {
        console.log(err);
        this.$toast.error(err.message)
        return null
      }
    },
    async getHealthPlanByPatient(healthPlanId) {
      if (!this.clinic.id || !healthPlanId) return
      try {
        const { data } = await this.api.getClinicHealthPlanByHealthId(this.clinic.id, healthPlanId, false)
        if (!data) {
          this.$toast.warning('Convênio selecionado não vinculado a clínica')
          return
        }
        this.clinicHealthPlan = data
        this.tissRequiredCustomField =
          this.clinicHealthPlan.invoicing_tiss_setting.tiss_information.tiss_required_custom_field
      } catch (error) {
        this.$toast.error(error.message)
      }
    },
    procedureValue(healthPlanProps) {
      if (!healthPlanProps?.id) return 0
      return healthPlanProps.medical_fee + healthPlanProps.operational_cost + healthPlanProps.m2_filme + healthPlanProps.anesthetic_port
    },
  },
}
</script>

<style lang="scss">
#new-tiss-guide-modal {
  .modal-content {
    border-radius: 8px;

    .modal-body {
      padding: 0 !important;
      margin: 0 !important;

      .modal-header {
        width: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px !important;

        .modal-title {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          color: var(--type-active);
        }

        .icon {
          width: 25px;
          fill: var(--neutral-500);
        }
      }

      .around-content {
        padding: 24px 24px 0 24px;
      }
      .wrapper-button {
        width: 100%;
        display: inline-flex;
        margin: 20px 0 !important;
        justify-content: flex-end;

        button {
          font-family: 'Nunito Sans';
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: var(--neutral-000);
        }
      }
    }
  }
  .disabled-description {
    font-size: 14px;
    font-style: italic;
    color: var(--type-active);
  }
}
</style>
